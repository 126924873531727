import * as PIXI from 'pixi.js';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import eSoundType from '../../sounds/eSoundType';
import FlashLib from 'flashlib_onlyplay';
import eSymbolIndex from '../symbols/eSymbolIndex';
import eEventTypes from '../../enums/eEventTypes';
import GameModel from '../../model/GameModel';


export default class ReelsContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
    this.addListeners();
    this.initControllerReels();
  }

  init() {
    this.hasScatter = false;
    this._scatterSymbols = [];

    this.ControllerReels = this.getChildByName('controllerReels');
    this._drawMask();
  }

  initControllerReels() {
    this.ControllerReels.init(EntryPoint.configData.reels.availableReelsNormal);
  }

  addListeners() {
    this.ControllerReels.onHittingBar = this.onHitBar.bind(this);
    this.ControllerReels.onAllReelsStopped = this.onAllReelsStopped.bind(this);
    GlobalDispatcher.add(eEventTypes.EET_WINNINGS_CONTROLLER__SLOW_WIN, this.onSlowWin, this);
  }

  onHitBar(reelIndex) {
    if (reelIndex === 3) GlobalDispatcher.dispatch(eEventTypes.EET_FOURTH_REEL_STOPPED);

    const symbols = this.ControllerReels.reels[reelIndex].symbols;
    for (let i = this.ControllerReels.topSimbolsCount; i <= symbols.length - 1 - this.ControllerReels.bottomSimbolsCount; i++) {
      const symbol = symbols[i];
      if (symbol.id === eSymbolIndex.ESI_SCATTER) {
        this.hasScatter = true;
        this._scatterSymbols.push(symbol);
      }
    }

    //'stop sound'
    if (EntryPoint.GameSettings.quickStop && reelIndex === 2) {
      SoundManager.play(eSoundType.EST_REEL_STOP, 0.5 + 0.2 * Math.random())
      if (this.hasScatter) {
        this.hasScatter = false;
        SoundManager.play(eSoundType.EST_SCATTER_STOP, 0.3 + 0.2 * Math.random())
      }
    } else if (!EntryPoint.GameSettings.quickStop) {
      SoundManager.play(eSoundType.EST_REEL_STOP, 0.5 + 0.2 * Math.random())
      if (this.hasScatter) {
        SoundManager.play(eSoundType.EST_SCATTER_STOP, 0.3 + 0.2 * Math.random())
        this.hasScatter = false;
      }
    }
  }

  onAllReelsStopped() {
    GlobalDispatcher.dispatch(eEventTypes.EET_REELS_STOPPED);
  }

  onSlowWin(data) {
    this._showWinningSymbols(data);
  }

  start() {
    this._scatterSymbols = [];
    this.ControllerReels.start();
  }

  stop(data) {
    this.data = data;
    if (EntryPoint.GameSettings.quickStop || EntryPoint.GameSettings.quickSpin) {
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.impactCoefficient = 0.15;
      this.ControllerReels.symbolsCountForStop = 3;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 1]
    } else {
      this.ControllerReels.bounceRelativeHeight = 0.2;
      this.ControllerReels.symbolsCountForStop = [4, 7, 10, 13, 16];
      this.ControllerReels.impactCoefficient = 0.15;
      this.ControllerReels.stopBezierSpeedPoints = [1, 1, 1, 1, 0.5]
    }
    this.ControllerReels.stop(this.data);
  }

  setActive(value) {
    this.ControllerReels.reels.forEach(reel => reel.symbols.forEach(symbol => symbol.setActive(value)))
  }

  _showWinningSymbols(data) {
    this.ControllerReels.reels.forEach(reel => reel.symbols.forEach(symbol => symbol.stopFrame()))
    const winLineIds = [];
    data.params.path.forEach((pathData, index) => {
      if (pathData >= 0) {
        const symbol = this.ControllerReels.reels[index].symbols[pathData];
        winLineIds.push(symbol.id);
        symbol.startAnimation();
      }
    });

    GlobalDispatcher.dispatch(eEventTypes.EBET_SET_WIN_LINE_TO_UI, {
      winLineIds,
      lineNumber: data.params.line,
      winAmount: data.params.totalWin,
      winLineCoef: data.params.coef,
      multipliers: GameModel.extraCoef > 1 ? [GameModel.extraCoef] : [],
    });
  }

  setActiveWinningSymbol() {
    const lines = EntryPoint.GameModel.winnings.lines
    const scatters = EntryPoint.GameModel.winnings.scatters || [];
    const allLines = [...lines, ...scatters]
    allLines.forEach((line) => {
      line.path.forEach((pathData, index) => {
          this.ControllerReels.reels[index].symbols[pathData].setActive(true);
      })
    })
  }

  _drawMask() {
    let mask = new PIXI.Graphics();
    mask.beginFill(0x0, 1);
    mask.drawRect(0, 49, 1360, 684);
    mask.endFill();
    this.addChild(mask);
    this.mask = mask;
  }
}
