import * as PIXI from 'pixi.js';

window.PIXI = PIXI;
require('pixi-spine');
import { Stage } from '@pixi/layers';
import 'Engine/utils/number';
import 'Engine/utils/flashlibExtantion';
import '../assets/scss/styles.scss';
import FlashLib from 'flashlib_onlyplay';
import './Imports';
import BaseGame from 'Engine/BaseGame';
import StatesManager from 'Engine/base/states/StatesManager';
import GameModel from './model/GameModel';
import { StatesConfig } from './states/StatesConfig';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import BaseGameSettings from 'Engine/base/settings/BaseGameSettings';
import AutoPlaySettings from 'Engine/base/settings/AutoPlaySettings';
import ControllerTextField from './controllers/textField/ControllerTextField';
import OPWrapperService from './libs/op-wrapper-service';
import eEventTypes from './enums/eEventTypes';
import background from '../assets/images/preloader_background.webp';
import wrapperConfig from './configs/wrapperConfig';
import preloaderConfig from './preloader/preloaderConfig';
import 'Preloader/preloader';
import BaseSystemUiAdapter from './libs/game-engine/src/base/ui/uiAdapter/BaseSystemUiAdapter';
import eSoundType from './sounds/eSoundType';
import Config from './Config';

export default new (class Game extends BaseGame {
  constructor(preloaderConfig) {
    EntryPoint.compiled = true;
    super(preloaderConfig);

    EntryPoint.Game = this;
  }

  initStartClasses() {
    new OPWrapperService({ ...wrapperConfig, scaleData: { data: this.scaleData } });

    GameModel.init();
    new BaseGameSettings().init();
    new AutoPlaySettings().init();
    StatesManager.setStatesConfig(StatesConfig);
    new BaseSystemUiAdapter(window.OPWrapperService.SystemUI).init({
      clickSound: { soundName: eSoundType.EST_BUTTON_CLICK, volume: 1, group: 'default' },
    });

    FlashLib.TextField = ControllerTextField;

    GlobalDispatcher.add(
      eEventTypes.EET_SPIN_CLICK,
      StatesManager.goToNextState,
      StatesManager
    );
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.drawMask,
      this
    );
  }

  initStartParams() {
    this.gameSize = {
      desktop: {
        width: 2340,
        height: 1080,
      },
      mobileLandscape: {
        width: 2340,
        height: 1080,
      },
      mobilePortrait: {
        width: 1080,
        height: 2340,
      },
    };
    this.scaleData = {
      app: this.app,
      gameSize: this.gameSize,
    };
  }

  createPreloader() {
    super.createPreloader();
    this.preloaderView.style.background = `url(${background}) no-repeat center`;
    this.preloaderView.style.backgroundSize = `cover`;
  }

  constructGame() {
    this.app.stage = new Stage();
    EntryPoint.configData = PIXI.Assets.get('gameConfig');
    this.app.stop();
    this.main = FlashLib.createItemFromLibrary('Main', 'GameFlashLib');
    this.app.stage.addChildAt(this.main, 0);
    const jackpotGame = FlashLib.createItemFromLibrary('jackpotMain', 'jackpotGameFlashLib');
    this.main.addChild(jackpotGame);
    this.main.addChildAt(jackpotGame.getChildByName('jackpotPanels'), 3);
    this.main.jackpotGame = jackpotGame;
    this.main.init();

    this.app.renderer.prepare.upload(this.main).then(() => {
      this.app.start();
      GlobalDispatcher.dispatch(eEventTypes.EET_GAME__STARTED);
      this.hidePreloader();
      this.linkToPixi();
    });
  }

  drawMask(data) {
    if (!this.main) return;

    const background = this.main.getChildByName('backgroundContainer');
    const x = data.isMobile && data.isPortrait ? 630 : 0;
    const y = data.isMobile && data.isPortrait ? -630 : 0;
    if (!this.gameMask) {
      this.gameMask = new PIXI.Graphics();
    } else {
      this.gameMask.clear();
    }
    this.gameMask.beginFill(0xff3300);
    this.gameMask.drawRect(x, y, data.gameWidth, data.gameHeight);
    this.gameMask.endFill();
    background.addChild(this.gameMask);
    this.main.mask = this.gameMask;
  }

  onLoadingComplete() {
    this.constructGame();
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config);
  }

})(preloaderConfig);
