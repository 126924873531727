import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import { startScreenFontStyle } from '../../fontStyles';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import SoundManager from 'Engine/soundManager/SoundManager';
import eSoundType from '../../sounds/eSoundType';
import eEventTypes from '../../enums/eEventTypes';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import { shadowText } from 'Engine/utils/fontStyles';
import { uiConfig } from '../ui/config';
import UiAdapter from 'Engine/base/ui/uiAdapter/BaseUiAdapter';
import eSymbolIndex from '../symbols/eSymbolIndex';
import uiWinLineAssets from '../../../assets/exported/UIWinLine/win_lines.webp';

export default class StartScreenContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.onKeyDown = this.onKeyDown.bind(this);

    this.init();
    this.addListeners();
  }


  onResize(data) {
    if (data.isLandscape) {
      this.background.goToFrame(2)
      this.content.visible = true;
      this.portretContent.visible = false;
      this.startButton.pivot.x = 0;
      this.startButton.x = this.startButton.displayData.x;
      this.startButton.y = this.startButton.displayData.y;
      this.portretSlot.stop();
      this.slot.playAnimation(this.slot.animationTypes.CIRCLE, 400000, 1500, 0, true);
    } else if (data.isMobile && data.isPortrait) {
      this.background.goToFrame(1)
      this.content.visible = false;
      this.portretContent.visible = true;
      this.startButton.pivot.x = this.startButton.displayData.width / 2;
      this.startButton.x = this.startButton.displayData.x + this.startButton.displayData.width / 2;
      this.startButton.y = this.startButton.displayData.y + 120;
      this.slot.stop();
      this.portretSlot.playAnimation(this.portretSlot.animationTypes.CIRCLE, 400000, 1500, 0, true)
    }
  }

  init() {
    this.content = this.getChildByName('content');
    this.portretContent = this.getChildByName('portretContent');
    this.background = this.getChildByName('background');
    this.visible = false;
    this.eventMode = 'static';
    this.startButton = this.getChildByName('startButton');
    makeHitArea(this.startButton);
    this.setTextsStyle();
    this.setWinAmounts();
    this.slot = this.content.getChildByName('slot');
    this.portretSlot = this.portretContent.getChildByName('slot');
  }

  addListeners() {
    GlobalDispatcher.addOnce(eEventTypes.EET_START_SCREEN__SHOW, this.show.bind(this));
    this.startButton.on('pointertap', this.hide, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
  }

  setTextsStyle() {
    const playText = this.startButton.getChildByName('start_screen_play');
    playText.style = { ...playText.style, ...shadowText }
    const setFunction = (content) => {
      for (let i = 1; i < 2; i++) {
        const text = content.getChildByName(`start_screen_${i}`)
        if (text) text.style = { ...text.style, ...startScreenFontStyle }
      }
      for (let i = 3; i <= 5; i++) {
        const coef = content.getChildByName(`wildsCoef_${i}`)
        coef.style = { ...coef.style, ...startScreenFontStyle }
      }
    }
    setFunction(this.content);
    setFunction(this.portretContent);
  }

  setWinAmounts() {
    const showCurrency = true;
    const setFunction = (content) => {
      const wildCoefs = EntryPoint.GameModel.paytableInfo[1];
      const maxBet = EntryPoint.GameModel.betPossible[EntryPoint.GameModel.betPossible.length - 1];
      const decimal = EntryPoint.GameModel.currencyInfo.decimals;
      const lines = EntryPoint.configData.lines.count;

      for (let i = 3; i <= 5; i++) {
        const coef = content.getChildByName(`wildsCoef_${i}`)
        coef.text = `- ${MoneyFormat.format(wildCoefs[i - 1] * maxBet / lines, showCurrency)}`;
        coef.fitSize(true, true);
        coef.correctPosition(null, 'center');
      }
    }
    setFunction(this.content);
    setFunction(this.portretContent);
  }

  onKeyDown(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      this.hide();
    }
  }

  show() {
    document.addEventListener('keydown', this.onKeyDown)
    this.startButton.enabled = true;
    this.visible = true;
  }

  hide() {
    this.visible = false;
    this.startButton.enabled = false;
    this.slot.stop();
    this.portretSlot.stop();
    GlobalDispatcher.dispatch(eEventTypes.EET_START_SCREEN__HIDE);
    SoundManager.play(eSoundType.EST_BACKGROUND, 0.5, true, 'music');
    document.removeEventListener('keydown', this.onKeyDown);
    OPWrapperService.freeBetsController.show();

    const ControllerUi = window.OPWrapperService.initUi(uiConfig);
    new UiAdapter(ControllerUi).init({
      clickSound: { soundName: eSoundType.EST_BUTTON_CLICK, volume: 0.7, group: 'default' },
      winLineConfig: {
        atlas: uiWinLineAssets,
        idToOptionsAdapter: {
          [eSymbolIndex.ESI_WILD]: { viewBox: '1 1 48 48'},
          [eSymbolIndex.ESI_KALYNA]: { viewBox: '1 136 47 48'},
          [eSymbolIndex.ESI_SABERS]: { viewBox: '1 240 46 48'},
          [eSymbolIndex.ESI_ROOSTER]: { viewBox: '1 292 43 48'},
          [eSymbolIndex.ESI_PALYANITSYA]: { viewBox: '1 99 48 33'},
          [eSymbolIndex.ESI_PYSANKA]: { viewBox: '1 344 39 48'},
          [eSymbolIndex.ESI_WHEET]: { viewBox: '1 188 47 48'},
          [eSymbolIndex.ESI_SCATTER]: { viewBox: '1 53 48 42'},
        },
      }
    });
  }
}
